import {lazy} from "react"

const MainHeaderLayout = lazy(() => import("./MainHeaderLayout"))
import {Outlet} from "react-router-dom";

const MainFooterLayout = lazy(() => import("./MainFooterLayout"))
import {Suspense} from "react";
import Loading from "../pages/Loading";

export default function RootTemplateLayout() {

    return (
        <Suspense fallback={<Loading/>}>
            <MainHeaderLayout/>
            <Outlet/>
            <MainFooterLayout/>
        </Suspense>
    )
}