import {lazy, Suspense} from 'react';
import './App.css';
import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider} from "react-router-dom";
import RootTemplateLayout from "./layouts/RootTemplateLayout";

const Home = lazy(() => import("./pages/Home"));
const NotFound = lazy(() => import("./pages/NotFound"));
const AboutUs = lazy(() => import("./pages/AboutUs"));
const Services = lazy(() => import( "./pages/Services"));
const ContactUs = lazy(() => import("./pages/ContactUs"));

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route>
            <Route path={"/"} element={<RootTemplateLayout/>}>

                <Route index element={<Home/>}/>

                {/*will handle about us request*/}
                <Route path={"about-fastlink-safaris"} element={<AboutUs/>}/>

                {/*will handle services request*/}
                <Route path={"services-offered-by-fastlink-safaris"} element={<Services/>}/>

                {/*will handle contact us path request*/}
                <Route path={"contact-us"} element={<ContactUs/>}/>
            </Route>

            {/*<Route path={"/loading"} element={<Loading/>}/>*/}

            {/*will handle all un-matched routes*/}
            <Route path={"*"} element={
                <Suspense fallback={<h1>Loading</h1>}>
                    <NotFound/>
                </Suspense>
            }/>
        </Route>
    )
)

export default function App() {
    return (
        <RouterProvider router={router}/>
    );
}